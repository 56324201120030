import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { Image } from "@atoms/Image"
// import Prism from '@atoms/Prism'
import SEO from "@atoms/Seo"
import * as Spacer from "@atoms/Spacer"
import { ItemGallery } from "@projects/ItemGallery"
import { ItemDetail } from "@projects/ItemDetail"
import { AccordionBox } from "@projects/AccordionBox"

import * as css from "@css/layouts/l-item-detail.module.styl"

// import { gsap } from "gsap"
// import { ScrollTrigger } from 'gsap/ScrollTrigger'
// gsap.registerPlugin(ScrollTrigger)

function ItemPage({data})
{
    console.log(data)
    const { title, content, thumbnail, acf_item} = data.post

    //images
    const images = []
    for(let i = 0; i < 10; i ++){
        let num = i + 1
        if( num < 10 ){
            num = `0${num}`
        }
        images.push(acf_item[`img${num}`])
    }
    const createAccordionTexts = () => {
        const { moreDetail } = acf_item
        if( !moreDetail ) return
        // let txt = moreDetail.replace(/\r\n/g, '<br>')
        let reg = new RegExp(/\[.+\]\r\n/g)
        let titles = moreDetail.match(reg)
        let descs = moreDetail.split(reg)
        descs.shift()
        let res = []
        console.log('====>', titles, descs)
        for(let i in titles ){
            let title = titles[i].replace(/\r\n|\[|\]/g,'')
            let content = descs[i].replace(/\r\n/g,'<br>')
            res[i] = <AccordionBox key={i} label={title} content={content} />
        }
        return res
    }
    
    return(
        <>
            <SEO
                subtitle={title}
                description={content}
                image={thumbnail && `${thumbnail.node.localFile.publicURL}`}
                noIndex={acf_item.isHide ? true : false}
            />
            <Spacer.Header/>
            <article className={css.lItemDetail}>
                <div className={css.aInner}>
                    <div className={css.aCol2}>
                        <figure className={css.aGallery}>
                            <ItemGallery data={images}   />
                        </figure>
                        <div className={css.aDetail}>
                            <ItemDetail data={data.post}/>
                        </div>
                    </div>
                    <div className={css.aInfo}>
                        { createAccordionTexts() }
                    </div>
                </div>
            </article>
        </>
    )
}

export const pagerQuery = graphql`
    fragment PostThumbnail on ImageSharp {
        gatsbyImageData(
            width: 1280
            placeholder: BLURRED
            formats: [AUTO]
            quality: 100
            layout: FULL_WIDTH
        )
    }
    fragment Item on WpPost {
        slug
        date#(formatString: "YYYY.MM.DD")
        title
        content
        uri
        databaseId
        thumbnail: featuredImage {
            node {
                localFile {
                    publicURL
                    childImageSharp {
                        ...PostThumbnail
                    }
                }
            }
        }
        cat: categories {
            nodes {
                slug
                name
            }
        }
        tags {
            nodes {
                slug
                name
            }
        }
        
        acf_item {
            itemId
            itemStatus
            price
            type
            detail
            moreDetail
            img01 { localFile { childImageSharp { ...PostThumbnail } } }
            img02 { localFile { childImageSharp { ...PostThumbnail } } }
            img03 { localFile { childImageSharp { ...PostThumbnail } } }
            img04 { localFile { childImageSharp { ...PostThumbnail } } }
            img05 { localFile { childImageSharp { ...PostThumbnail } } }
            img06 { localFile { childImageSharp { ...PostThumbnail } } }
            img07 { localFile { childImageSharp { ...PostThumbnail } } }
            img08 { localFile { childImageSharp { ...PostThumbnail } } }
            img09 { localFile { childImageSharp { ...PostThumbnail } } }
            img10 { localFile { childImageSharp { ...PostThumbnail } } }
            isNewer
            isHide
            isHideTop
            stockIslimit
            stockCount
            orderDateFrom
            orderDateTo
            orderReservationClose
            mainCategory {
                name
                slug
            }
            overridePrice
        }
    }
    query PostBySlug($id: String!){
        post: wpPost( id: { eq: $id }){
            ...Item
        }
    }
`
export default ItemPage